import React from 'react';
import BlogItem3 from '../components/BlogItem3';
import { Helmet } from "react-helmet";
const Restaurant = () => {
  return (
    <>
      <Helmet>
        <title>How To Give Training Of Restaurant Billing Software To My Employees </title>

        <meta name="description" content="As the manager or owner, make sure you are well-versed in the billing software. Get acquainted with all of its settings, features, and functions. Hungertime is the one and only software team who train owners or managers with no time limit." />

        <meta name="keywords" content="Restaurant-billing-software" />

      </Helmet>

      <BlogItem3 />


    </>
  )
}

export default Restaurant;