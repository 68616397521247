import React from 'react';
import BlogItem5 from '../components/BlogItem5';
import { Helmet } from "react-helmet";

const PosTableManagementPunjab = () => {
  return (
    <>
      <Helmet>
        <title>Streamlining Restaurant Operations: The Power of POS Table Management </title>

        <meta name="description" content="Restaurants can now easily manage reservations using POS table management. This system visually represents the restaurant layout." />

        <meta name="keywords" content=" POS table management, Restaurant Management Tools, Real-Time Table Status Updates" />
        
      </Helmet>

      <BlogItem5 />
    </>
  )
}

export default PosTableManagementPunjab;