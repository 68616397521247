import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserTie } from "@fortawesome/free-solid-svg-icons";
import { faIndent } from "@fortawesome/free-solid-svg-icons";
import { faIndustry } from "@fortawesome/free-solid-svg-icons";
import { faCodeBranch } from "@fortawesome/free-solid-svg-icons";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { faCartPlus } from "@fortawesome/free-solid-svg-icons";
import { faHeadset } from "@fortawesome/free-solid-svg-icons";
import dividerImg from "../images/divider.png";
import Sliders from "react-slick";
const ServicesItem = () => {

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // Add more breakpoints and settings as needed
    ],
  };
  return (
    <>
      <section className="services-item">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="header-part-brand mb-5">
                <h5 className="about-title brand-header">Our Features</h5>
                <h4>Explore Our Features</h4>
                <div className="divider-area">
                  <img src={dividerImg} alt="" />
                </div>
              </div>
            </div>

            <Sliders {...settings}>
              <div className="col-sm-6 col-lg-3">
                <div className="services-area mb-5">
                  <FontAwesomeIcon icon={faUserTie} />
                  <h5>Menu Management</h5>
                  <p>
                    1. Take control of your menu items <br /> 2. Define your
                    delivery areas. <br />
                    3. Select the item categories.
                  </p>
                </div>
              </div>

              <div className="col-sm-6 col-lg-3">
                <div className="services-area mb-5">
                  <FontAwesomeIcon icon={faUsers} />
                  <h5>Order Management</h5>
                  <p>
                    1.Order Tracking and Visibility <br /> 2.Recieve orders{" "}
                    <br /> 3.Payment Processing <br />{" "}
                  </p>
                </div>
              </div>

              <div className="col-sm-6 col-lg-3">
                <div className="services-area mb-5">
                  <FontAwesomeIcon icon={faIndustry} />
                  <h5>Staff Management</h5>
                  <p>
                    1.Staff Database <br /> 2.Staff Attendance <br /> 3.Staff
                    Positions <br />
                  </p>
                </div>
              </div>

              <div className="col-sm-6 col-lg-3">
                <div className="services-area mb-5">
                  <FontAwesomeIcon icon={faHeadset} />
                  <h5>Inventory Management</h5>
                  <p>
                    Monitoring Food Items It's simple to monitor when a product
                    runs out of stock.
                  </p>
                </div>
              </div>

              <div className="col-sm-6 col-lg-3">
                <div className="services-area mb-5">
                  <FontAwesomeIcon icon={faCodeBranch} />
                  <h5>Branch Management</h5>
                  <p>
                    1.Real-Time Updates <br /> 2.Customer Relationship
                    Management systems <br /> 3.Unified Reporting and Analytics
                  </p>
                </div>
              </div>

              <div className="col-sm-6 col-lg-3">
                <div className="services-area mb-5">
                  <FontAwesomeIcon icon={faIndent} />
                  <h5>Software Quality</h5>
                  <p>
                    1. User-friendly <br /> 2.Cloud-Based <br /> 3.Simple to Set
                    Up
                  </p>
                </div>
              </div>

              <div className="col-sm-6 col-lg-3">
                <div className="services-area mb-5">
                  <FontAwesomeIcon icon={faCartPlus} />
                  <h5>Record Management</h5>
                  <p>
                    Every record is automatically calculated. It doesn't require
                    any manual effort.
                  </p>
                </div>
              </div>

              <div className="col-sm-6 col-lg-3">
                <div className="services-area mb-5">
                  <FontAwesomeIcon icon={faHeadset} />
                  <h5>24/7 Service</h5>
                  <p>We are here to help you 365 days a year.</p>
                  <p>Our team is available day and night to help you out.</p>
                </div>
              </div>
            </Sliders>
           
          </div>
        </div>
      </section>
    </>
  );
};

export default ServicesItem;
