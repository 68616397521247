import React from 'react';
import { NavLink } from "react-router-dom";
const BlogCategories = () => {
    return (
        <>
            <div className="columns categories">
                <span className="title">Categories</span>
                <section className="category-area">
                    <NavLink >Pizza</NavLink>
                    <NavLink >Burger</NavLink>
                    <NavLink >Noodles</NavLink>
                    <NavLink >Samosa</NavLink>
                    <NavLink >Veg Momos</NavLink>
                    <NavLink >Corn Pizza</NavLink>
                    <NavLink >Only Cheese Pizza</NavLink>
                </section>
            </div>
        </>
    )
}

export default BlogCategories;