import React, { useState, useEffect } from "react";
import { MDBValidation, MDBValidationItem, MDBInput, MDBBtn, MDBTextArea } from "mdb-react-ui-kit";
import { useForm } from '@formspree/react';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";

type FormState = {
  S_name: string;
  S_email: string;
  S_date: string;
  S_phone: string;
  S_message: string;
};

type FormSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => void;

function Form() {
  const [state, handleSubmit]: [FormState, FormSubmitHandler] = useForm("xvojnlol");
  const navigate = useNavigate();
  const [showThanks, setShowThanks] = useState(false);
  const onChange = () => { };

  useEffect(() => {
    if (state.succeeded) {
      const navigateTimeout = setTimeout(() => {
        setShowThanks(true);
        navigate('/');
      }, 1000);

      return () => clearTimeout(navigateTimeout);
    }
  }, [state.succeeded, navigate]);

  return (

    <MDBValidation className="row g-3" action="https://formspree.io/f/xvojnlol" method="POST" onSubmit={handleSubmit}>
      {showThanks ? (
        <p>Thanks for joining!</p>
      ) : (
        <>
          <MDBValidationItem className="col-md-6">
            <MDBInput type="text" name="S_name" required label="Your Name" />
          </MDBValidationItem>

          <MDBValidationItem className="col-md-6">
            <MDBInput type="text" name="S_email" required label="Email" />
          </MDBValidationItem>

          <MDBValidationItem className="col-md-6">
            <MDBInput type="date" name="S_date" required label="Date" />
          </MDBValidationItem>

          <MDBValidationItem className="col-md-6">
            <MDBInput type="phone" name="S_phone" required label="Phone No:" />
          </MDBValidationItem>

          <MDBValidationItem className="col-md-12">
            <MDBTextArea label='Message' name="S_message" type="text" rows={4} />
          </MDBValidationItem>

          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-8">
            <div className="recaptcha-area">
              <ReCAPTCHA className="g-recaptcha" sitekey="6LcvEEQpAAAAAE2NtFHxdtwJMqbvgbPeyq41oIax" onChange={onChange} />
            </div>
          </div>

          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-4">
            <MDBBtn className="btn-hero" type="submit">Send Now</MDBBtn>
          </div>
        </>
      )}
    </MDBValidation>
  );
}
export default Form;
