import React from 'react';
import FeaturesItem from '../components/FeaturesItem';
import { Helmet } from "react-helmet";
const Features = () => {
  return (
    <>

    <Helmet>
        <title>Best Restaurant Inventory Management Software In India </title>

        <meta name="description" content="Get notified with the best restaurant inventory management software when a product runs out of stock. Billing for restaurants is made easy with Hungertime's all-in-one restaurant POS billing software." />
        
        <meta name="keywords" content="restaurant inventory management software" />
        
      </Helmet>

    <FeaturesItem />
    
    </>
  )
}

export default Features;