import React from "react";
import ContactArea from "../components/ContactArea";
import { Helmet } from "react-helmet";
const ContactUs = () => {
  return (
    <>
    <Helmet>
        <title>Contact Hungertime for any Query </title>

        <meta name="description" content="Hungertime software offers the most convenience in today's time. Contact us today to make the journey of taking your restaurant to new heights easier.
" />
        
        <meta name="keywords" content="hungertime contact info, best pos billing software company India, menu management, all in one restaurant management software Punjab
" />
        
      </Helmet>
      <ContactArea />
    </>
  );
};

export default ContactUs;
