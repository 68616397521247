import React from 'react';
import { NavLink } from "react-router-dom";
import BlogImg from "../images/inventory-management.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare } from "@fortawesome/free-solid-svg-icons";
const BlogRecentlyPost = () => {
    return (
        <>
            <div className="columns posts">
                <span className="title">
                    Recent Posts
                    <div className="share-btn">
                        <FontAwesomeIcon icon={faShare} />
                    </div>
                </span>
                <section className="Post-blog">
                    <NavLink >
                        <img src={BlogImg} /> <p>Be brave, be strong, be bold. Man dies but once. </p>
                    </NavLink>
                    <NavLink >
                        <img src={BlogImg} /> <p>Be brave, be strong, be bold. Man dies but once. </p>
                    </NavLink>
                    <NavLink >
                        <img src={BlogImg} /> <p>Be brave, be strong, be bold. Man dies but once. </p>
                    </NavLink>
                </section>
            </div>
        </>
    )
}

export default BlogRecentlyPost;