import React from 'react';
import { Helmet } from "react-helmet";
import BlogItem4 from '../components/BlogItem4';
const FoodDeliveryProblems = () => {
    return (
        <>
            <Helmet>
                <title>TOP CHALLENGES FACED BY ONLINE FOOD DELIVERY SERVICES </title>
            </Helmet>


            <BlogItem4 />












        </>
    )
}

export default FoodDeliveryProblems;