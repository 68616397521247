import React from 'react';
import BlogItem2 from '../components/BlogItem2';
import { Helmet } from "react-helmet";
const FoodIndustrySoftware = () => {
  return (
    <>
      <Helmet>
        <title>How Softwares Arrange Smooth Operations In The Food Industry </title>

        <meta name="description" content="The software arranges smooth operations in the food industry in this blog. Difficult work of staff scheduling is made simpler." />

        <meta name="keywords" content=" food industry software" />
        
      </Helmet>

      <BlogItem2 />
    </>
  )
}

export default FoodIndustrySoftware;