import React from 'react';
import { NavLink } from "react-router-dom";
import Slider from "react-slick"; // Make sure to import the Slider component
import BlogImg from "../images/restaurant-managemnt-soft.jpg";
import BlogImg2 from "../images/inventory-management.jpg";
import BlogImg3 from "../images/employee-software-training.jpg";
import BlogImg4 from "../images/hidden-charges.jpg";
import BlogImg5 from "../images/table-management.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const BlogSlider = () => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
            // Add more breakpoints and settings as needed
        ]
    };
    return (
        <>
            <div className="blog-slider">
                <Slider {...settings}>
                    <div>
                        <div className="blog-slide-card">
                            <NavLink to="/Rms"><img src={BlogImg} alt="" /></NavLink>
                            <div className="inner-blog-card">
                                <NavLink to="/Rms"> <h2>How RMS Can Accelerate Your Food Business To New Heights</h2></NavLink>
                                <p>Success in the dynamic and fast-paced world of the food industry depends on efficient management. Every day, managers and owners of restaurants juggle a variety of activities, such as staff scheduling, customer service, inventory management, and order processing.</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="blog-slide-card">
                            <NavLink to="/FoodIndustrySoftware"><img src={BlogImg2} alt="" /></NavLink>
                            <div className="inner-blog-card">
                                <NavLink to="/FoodIndustrySoftware"><h2>How Softwares Arrange Smooth Operations In The Food Industry</h2></NavLink>
                                <p>Technology integration has become essential for effective operations in the fast-paced food service sector. Restaurant Management Software has become a comprehensive system that has transformed the way restaurants manage inventory and process orders.</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="blog-slide-card">
                            <NavLink to="/restaurant-billing-software"><img src={BlogImg3} alt="" /></NavLink>
                            <div className="inner-blog-card">
                                <NavLink to="/restaurant-billing-software"><h2>How To Give Training Of Restaurant Billing Software To My Employees</h2></NavLink>
                                <p>Encouraging your employees to use restaurant billing software efficiently is essential to seamless operations and happy patrons. As the manager or owner, make sure you are well-versed in the billing software. Get acquainted with all of its settings, features, and functions.</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="blog-slide-card">
                            <NavLink to="/food-delivery-problems"><img src={BlogImg4} alt="" /></NavLink>
                            <div className="inner-blog-card">
                                <NavLink to="/food-delivery-problems"><h2>Top Challenges Faced by Online Food Delivery Services</h2></NavLink>
                                <p>Online food delivery services have become very popular due to their convenience; driven by the convenience they offer to consumers seeking a quick and hassle-free dining experience. However, this industry faces some challenges that businesses must overcome to keep growing and keep their customers happy.</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="blog-slide-card">
                            <NavLink to="/pos-table-management-punjab"><img src={BlogImg5} alt="" /></NavLink>
                            <div className="inner-blog-card">
                                <NavLink to="/pos-table-management-punjab"><h2>The Power of POS Table Management</h2></NavLink>
                                <p>Efficiency is key in the fast-paced world of restaurant management. A key to success in the restaurant sector is the capacity to maximize operational effectiveness while providing great service. The Point of Sale (POS) system is one of the most important tools that managers.</p>
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>
        </>
    )
}

export default BlogSlider;