import React, { useState } from "react";
import "animate.css/animate.min.css";
import "aos/dist/aos.css";
import { Link, useNavigate } from "react-router-dom";
import image from "../images/hero.png";
const HeroImg = () => {
  const [openNav, setOpenNav] = useState(false);
  const navigate = useNavigate();
  const handleNavLinkClick = (path) => {
    navigate(path);
    setOpenNav(false);
  };

  return (
    <>
      <section className="hero-area">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="hero-description">
                <h1 className="animate__animated animate__slideInLeft animate__duration-1s ">Hungertime Restaurant Management Software</h1>
                <p className="animate__animated animate__slideInLeft animate__duration-1s"> An advanced feature-rich best restaurant management software in India With features like menu, staff, inventory, branch management, and more. Hungertime restaurant management software will help you write your restaurant's success! Hungertime software flexibility ensures that businesses can adapt to changing circumstances, seasonal variations, or unexpected supply chain challenges with remarkable agility. </p>
                <a className="btn-hero animate__animated animate__slideInLeft animate__duration-1s" tag={Link} to="/ContactUs" onClick={() => handleNavLinkClick("/ContactUs")}>
                  Contact Us
                </a>
              </div>
            </div>
            <div className="col-md-6">
              <div className="heroRotate-img">
                <img src={image} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroImg;
