import React from 'react';
import { NavLink } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
const BlogSocialMediaIcon = () => {
    return (
        <>
            <div className="follow-blog">
                                    <span className="title">Follow Me</span>
                                    <ul>
                                        <li>
                                            <NavLink>
                                                <FacebookIcon />
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink>
                                                <TwitterIcon />
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink>
                                                <YouTubeIcon />
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink>
                                                <LinkedInIcon />
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink>
                                                <InstagramIcon />
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink>
                                                <WhatsAppIcon />
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
        </>
    )
}

export default BlogSocialMediaIcon