import React from 'react'
import BlogItem from '../components/BlogItem';
import { Helmet } from "react-helmet";
const Rms = () => {
  return (
    <>
      <Helmet>
        <title>How RMS Can Accelerate Your Food Business To New Heights </title>

        <meta name="description" content="Restaurant Management Software (RMS) is a game-changer, emerged from the necessity for effective tools as the demands of operating a restaurant became complex." />

        <meta name="keywords" content=" RMS, restaurant management software" />
        
      </Helmet>

      <BlogItem />
    </>
  )
}

export default Rms;