import React from "react";
import BlogData from "./BlogData";
import { NavLink } from "react-router-dom";
const BlogCard = (props) => {
  // Add a check to handle empty or undefined props.data
  if (!props.data || props.data.length === 0) {
    return (
      <div>
        <BlogCard data={BlogData} />
      </div>
    );
  }

  return (
    <>
      <section className="banner-area">
        <div className="container">
          <div className="breadcrumb-area">
            <h1>Blog</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <NavLink>Home</NavLink>
                </li>
                <li className="breadcrumb-item">
                  <NavLink>Pages</NavLink>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                Blog
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>

      <section className="services-card pt-5">
        <div className="container">
          <div className="row">
            {props.data.map((item, index) => (
              <div className="col-md-4" key={index}>
                <div className="card">
                <NavLink to={`/${item.link}`} tag={item.link}>
                    <img src={item.imgsrc} alt={item.alt} className="card-image" />
                  </NavLink>
                  <div className="card-content">
                    <h2 className="card-title">{item.title}</h2>
                    <p className="card-description">{item.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};
export default BlogCard;
