import { faUtensils } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import CountUp from "react-countup";
import { Link, useNavigate, NavLink } from "react-router-dom";
import about1 from "../images/about-1.jpg";
import about2 from "../images/about-2.jpg";
import about3 from "../images/about-3.jpg";
import about4 from "../images/about-4.jpg";

const About = () => {
  const [openNav, setOpenNav] = useState(false);
  const navigate = useNavigate();

  const handleNavLinkClick = (path) => {
    navigate(path);
    setOpenNav(false);
  };

  return (
    <>
      <section className="about-area">
        <div className="container">
          <div className="row">

            <div className="col-md-6">
              <div className="row g-3">
                <div className="col-6">
                  <div className="about-img">
                    <img src={about1} alt="Pos Billing" />
                  </div>
                </div>

                <div className="col-6">
                  <div className="about-img2 text-start">
                    <img src={about2} className="w-75" alt="Inventory Management" />
                  </div>
                </div>

                <div className="col-6">
                  <div className="about-img text-end">
                    <img src={about3} className="w-75" alt="Restaurant Management" />
                  </div>
                </div>

                <div className="col-6">
                  <div className="about-img">
                    <img src={about4} alt="Table Management" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="about-description">
                <h5 className="about-title">About Us</h5>
                <h2 className="about-heading">
                  Welcome to <FontAwesomeIcon icon={faUtensils} /> Hungertime
                </h2>
                <p>Thirteen years of IT experience goes into creating Hungertime Software. Restaurant operators benefit from our online software's dedication to offering simple features for adding and modifying products. The application's intuitive user interface, instantaneous updates, and flexibility enable enterprises to remain agile in reacting to market fluctuations, upholding consumer contentment, and eventually prospering in the always-changing food sector.
                </p>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="counter-box">
                      <CountUp duration={10} end={15} />
                      <div className="count-box">
                        <p>Years of</p>
                        <h6>Experience</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="counter-box">
                      <CountUp duration={10} end={50} />
                      <div className="count-box">
                        <p>Popular</p>
                        <h6>MASTER CHEFS</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <NavLink className="btn-hero" tag={Link} to="/AboutUs" onClick={() => handleNavLinkClick("/AboutUs")}>Read More</NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
