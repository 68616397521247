import React, { useEffect } from "react";
import HeroImg from "../components/HeroImg";
import ServicesItem from "../components/ServicesItem";
import About from "../components/About";
import BrandItem from "../components/BrandItem";
import FormArea from "../components/FormArea";
import ClientReview from "../components/ClientReview";
import "../css/style.css";
import "../css/responsive.css";
import { Helmet } from 'react-helmet-async';
const Home = () => {

  const seo = {
    title: "Best Restaurant Management Software in India | Hungertime",
    description:
      "An advanced feature-rich best restaurant management software in India with features like menu, staff, inventory, branch management, and more. Hungertime restaurant management software will help you streamline your restaurant's operations.",
    keywords: "restaurant management software",
  };

  useEffect(() => {
    document.title = "Notification Page";
  }, [])
  return (
    <>

      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <meta name="keywords" content={seo.keywords} />
      </Helmet>

      <HeroImg />
      <ServicesItem />
      <About />
      <FormArea />
      <BrandItem />
      <ClientReview />

    </>
  );
};

export default Home;
