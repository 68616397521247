import React from "react";
import ClientReview from "../components/ClientReview";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
const Testimonial = () => {
  return (
    <>
    <Helmet>
        <title>All-In-One Restaurant Management Software in India | Hungertime </title>

        <meta name="description" content="restaurant administration has been easier because of Hunger Time's all-in-one restaurant management software." />
        
        <meta name="keywords" content="all-in-one restaurant management software" />
        
      </Helmet>

      <section className="banner-area">
        <div className="container">
          <div className="breadcrumb-area">
            <h1>Hungertime Restaurant Management Software Reviews</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <NavLink >Home</NavLink>
                </li>
                <li className="breadcrumb-item">
                  <NavLink >Pages</NavLink>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                Testimonial
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>

      <section className="brand-area">
        <div className="container">
          <div className="row">
            <ClientReview />
          </div>
        </div>
      </section>

      
      
    </>
  );
};

export default Testimonial;
