import * as React from 'react';
import { NavLink } from "react-router-dom";
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function Faq() {
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <>

            <section className="banner-area">
                <div className="container">
                    <div className="breadcrumb-area">
                        <h1>Frequently Asked Questions</h1>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <NavLink>Home</NavLink>
                                </li>
                                <li className="breadcrumb-item">
                                    <NavLink>Pages</NavLink>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    FAQ
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </section>

            <section className="faq-area">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10">
                            <div className="faq-inner">
                                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                        <Typography>What is Hungertime restaurant management software?</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        This Software is built to make day-to-day tasks in restaurants easy and everything trackable with some deep insights to boost the business.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                                        <Typography> How can Hungertime software benefit my business?</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        It can improve order accuracy, streamline billing processes, optimize inventory management, enhance customer service, and provide valuable insights through analytics, ultimately boosting overall restaurant performance.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                                        <Typography>Does Hungertime software help with inventory control?</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        Yes, you can track inventory levels, manage stock, and automate the order process, helping reduce waste and ensure menu availability with simple clicks.

                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                    <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                                        <Typography>How does this software contribute to employee management?</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        Employee scheduling, optimizing staff levels and record of employee’s biodata, all at one screen.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                    <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                                        <Typography> Is this software customizable for different types of restaurants?</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        Yes, software customization can be done as per your needs. Our team will completely help you out on this without any extra charges.

                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                    <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
                                        <Typography> Is my data secure with Hungertime restaurant management software?</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        Yes, security is our priority. Hungertime Restaurant management software typically includes features like user authentication, data encryption, and secure access controls to protect sensitive information. For more confirmation our team will help you .
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                                    <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
                                        <Typography> Can this software provide insights and analytics for decision-making?</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        Yes, restaurant management software often offers robust reporting and analytics tools, providing insights into sales trends, customer preferences, and overall performance to aid in informed decision-making.

                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
        </>
    );
}

export default Faq;