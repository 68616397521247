import React from "react";
import Sliders from "react-slick";
import testi_img1 from "../images/testimonial-1.jpg";
import testi_img2 from "../images/testimonial-2.jpg";
import testi_img3 from "../images/testimonial-3.jpg";
import dividerImg from "../images/divider.png";
import testi_img4 from "../images/testimonial-4.jpg";
function ClientReview() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // Add more breakpoints and settings as needed
    ],
  };

  return (
    <section className="testimonial-area">
      <div className="container">
        <div className="row">
        <div className="col-md-12">
              <div className="header-part-brand">
                <h5 className="about-title brand-header"> Reviews from Client's</h5>
                <h4>Our Client's Say!!!</h4>
                <div className="divider-area">
                  <img src={dividerImg} alt="" />
                </div>
              </div>
            </div>
        </div>
        <Sliders {...settings}>
          <div className="testi_slide">
            <div className="tf__single_testimonial">
              <div className="tf__single_testimonial_img">
                <img src={testi_img1} alt="client" className="img-fluid w-100" />
              </div>
              <div className="tf__single_testimonial_text">
                <p className="cliect_comment">"The software Hunger Time has completely changed the way our business operates! The intuitive UI and smooth order processing have greatly increased productivity. We value the real-time inventory updates, and our consumers adore the convenience. It's revolutionary!"</p>
                <h3 className="title">Tapish </h3>
              </div>
            </div>
          </div>

          <div className="testi_slide">
            <div className="tf__single_testimonial">
              <div className="tf__single_testimonial_img">
                <img src={testi_img2} alt="client" className="img-fluid w-100" />
              </div>
              <div className="tf__single_testimonial_text">
                <p className="cliect_comment">"Fantastic software Our order administration has been simplified with Hunger Time, making it simple to monitor sales and control inventories. We can make wise selections thanks to the analytical analytics that are supplied. The smooth integration with outside providers improves our overall clientele."</p>
                <h3 className="title">Rashid </h3>
              </div>
            </div>
          </div>

          <div className="testi_slide">
            <div className="tf__single_testimonial">
              <div className="tf__single_testimonial_img">
                <img src={testi_img3} alt="client" className="img-fluid w-100" />
              </div>
              <div className="tf__single_testimonial_text">
                <p className="cliect_comment">"Our restaurant administration has been easier because to Hunger Time. We can easily adjust to new menus because to the configurable options. The real-time stock level and price updates are priceless. Excellent customer service is provided, with prompt resolutions to any problems. Extremely well advised"</p>
                <h3 className="title">Bhushan </h3>
              </div>
            </div>
          </div>

          <div className="testi_slide">
            <div className="tf__single_testimonial">
              <div className="tf__single_testimonial_img">
                <img src={testi_img4} alt="client" className="img-fluid w-100" />
              </div>
              <div className="tf__single_testimonial_text">
                <p className="cliect_comment">"Hunger Time has changed the game for us in the few months that we've been utilizing it. Order processing is simple because to the user-friendly UI. We've minimized waste and saved time thanks to the inventory management tool. For any restaurant wishing to simplify operations, this is a dependable and effective option."</p>
                <h3 className="title"> Nishtha</h3>
              </div>
            </div>
          </div>
        </Sliders>
      </div>
    </section>
  );
}

export default ClientReview;
