// app.js (for client-side React code)

import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "./pages/home";
import AboutUs from "./pages/about-us";
import Features from "./pages/features";
import ContactUs from "./pages/contact-us";
import Testimonial from "./pages/testimonial";
import Blog from "./pages/blog";
import Rms from "./pages/rms";
import FoodIndustrySoftware from "./pages/food-industry-software";
import Faq from "./pages/faq";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Restaurant from "./pages/restaurant-billing-software";
import FoodDeliveryProblems from "./pages/food-delivery-problems";
import PosTableManagementPunjab from "./pages/pos-table-management-punjab";


function App() {
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/features" element={<Features />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/testimonial" element={<Testimonial />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/rms" element={<Rms />} />
          <Route path="/food-industry-software" element={<FoodIndustrySoftware />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/restaurant-billing-software" element={<Restaurant />} />
          <Route path="/food-delivery-problems" element={<FoodDeliveryProblems />} />
          <Route path="/pos-table-management-punjab" element={<PosTableManagementPunjab />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;