import React from "react";
import BrandSlider from "./BrandSlider";
import dividerImg from "../images/divider.png";
const BrandItem = () => {
  return (
    <>
      <section className="brand-area">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="header-part-brand">
                <h5 className="about-title brand-header">Our Happy Customers</h5>
                <h4>Trusted By 100+ Restaurants</h4>
                <div className="divider-area">
                  <img src={dividerImg} alt="" />
                </div>
              </div>
            </div>
            <BrandSlider />
          </div>
        </div>
      </section>
    </>
  );
};

export default BrandItem;
