// AboutUs.js
import React from "react";
import AboutBanner from "../components/AboutBanner";
import About from "../components/About";
import BrandItem from "../components/BrandItem";
import "../css/style.css";
import "../css/responsive.css";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>Hungertime software | About Us </title>

        <meta name="description" content="Thirteen years of IT experience goes into creating Hungertime Software. Restaurant operators benefit from our online software's dedication to offering simple features for adding and modifying products." />
        
        <meta name="keywords" content=" Hungertime software" />
        
      </Helmet>
      <AboutBanner />
      <About />
      <BrandItem />
    </>
  );
};

export default AboutUs;
