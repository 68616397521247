import React, { useState, useEffect } from "react";
import { MDBContainer, MDBNavbar, MDBNavbarBrand, MDBNavbarToggler, MDBNavbarNav, MDBNavbarItem, MDBNavbarLink, MDBCollapse } from "mdb-react-ui-kit";
import { MDBIcon } from "mdbreact";
import Logo from '../images/logo.png';
import { Link, useNavigate } from "react-router-dom";

function Header() {
  const [openNav, setOpenNav] = useState(false);
  const [navbarSticky, setNavbarSticky] = useState(false);
  const navigate = useNavigate();

  const handleNavLinkClick = (path) => {
    navigate(path);
    setOpenNav(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setNavbarSticky(true);
      } else {
        setNavbarSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <MDBNavbar expand="lg" className={`px-5 py-2 ${navbarSticky ? 'sticky-navbar' : ''}`}>
      <MDBContainer>
        <MDBNavbarBrand tag={Link} to="/" onClick={() => handleNavLinkClick("/")}>
          <img src={Logo} width="200" alt="" loading="lazy" />
        </MDBNavbarBrand>
        <MDBNavbarToggler type="button" aria-expanded="false" aria-label="Toggle navigation" onClick={() => setOpenNav(!openNav)}>
          <MDBIcon icon="bars" fas />
        </MDBNavbarToggler>
        <MDBCollapse navbar open={openNav}>
          <MDBNavbarNav className="justify-content-end">

            <MDBNavbarItem>
              <MDBNavbarLink active aria-current='page' onClick={() => handleNavLinkClick("/")} >
                Home
              </MDBNavbarLink>
            </MDBNavbarItem>

            <MDBNavbarItem>
              <MDBNavbarLink tag={Link} to="/about-us" onClick={() => handleNavLinkClick("/about-us")}>
                About
              </MDBNavbarLink>
            </MDBNavbarItem>

            <MDBNavbarItem>
              <MDBNavbarLink tag={Link} to="/features" onClick={() => handleNavLinkClick("/features")}>
                Features
              </MDBNavbarLink>
            </MDBNavbarItem>

            <MDBNavbarItem>
              <MDBNavbarLink tag={Link} to="/contact-us" onClick={() => handleNavLinkClick("/contact-us")}>
                Contact Us
              </MDBNavbarLink>
            </MDBNavbarItem>

            <MDBNavbarItem>
              <MDBNavbarLink tag={Link} to="/testimonial" onClick={() => handleNavLinkClick("/testimonial")}>
                Testimonials
              </MDBNavbarLink>
            </MDBNavbarItem>

            <MDBNavbarItem>
              <MDBNavbarLink tag={Link} to="/blog" onClick={() => handleNavLinkClick("/blog")}>
                Blog
              </MDBNavbarLink>
            </MDBNavbarItem>

          </MDBNavbarNav>
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>
  );
}

export default Header;
