import React from "react";
import Slider from "react-slick";
import logo1 from "../images/logo1.png";
import logo2 from "../images/logo2.png";
import logo3 from "../images/logo3.jpeg";
import logo4 from "../images/logo4.jpeg";
import logo5 from "../images/logo5.png";

function BrandSlider() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
      // Add more breakpoints and settings as needed
    ]
  };
  
  
  return (
    <Slider {...settings}>
      <div className="slick_slide">
        <img src={logo1} alt="" />
      </div>

      <div className="slick_slide">
        <img src={logo2} alt="" />
      </div>

      <div className="slick_slide">
        <img src={logo3} alt="" />
      </div>

      <div className="slick_slide">
        <img src={logo4} alt="" />
      </div>
      <div className="slick_slide">
        <img src={logo5} alt="" />
      </div>
    </Slider>
  );
}

export default BrandSlider;
