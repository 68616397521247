import React from "react";
import formImg from "../images/form-img.jpg";
import Form from "../components/Form";
const FormArea = () => {
  return (
    <>
      <section className="form-area">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="form-img">
                <img src={formImg} alt="" className="w-100" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-main">
              <h5 className="about-title">Reservation</h5>
                <h3>Book A Software Online</h3>
                <Form />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FormArea;
