import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import footer_logo from "../images/logo.png";
const Footer = () => {
  const [openNav, setOpenNav] = useState(false);
  const navigate = useNavigate();

  const handleNavLinkClick = (path) => {
    navigate(path);
    setOpenNav(false);
  };
  return (
    <>
      <footer className="tf_footer">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-3">
              <div className="footer-in">
                <img src={footer_logo} alt="" />

                <p>
                  "A thirst for excellence? A feast of order accuracy, dynamic menu control, and real-time mastery is provided by Hunger Time Software. Improve your restaurant operations since timing is crucial!

                </p>

                <div className="social-media">
                  <ul>
                    <li>
                      <NavLink>
                        <FacebookIcon />
                      </NavLink>
                    </li>
                    <li>
                      <NavLink>
                        <TwitterIcon />
                      </NavLink>
                    </li>
                    <li>
                      <NavLink>
                        <YouTubeIcon />
                      </NavLink>
                    </li>
                    <li>
                      <NavLink>
                        <LinkedInIcon />
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="inner-footer">
                <h4 className="about-title">Company</h4>
                <ul>
                  <li>
                    <NavLink tag={Link} to="/AboutUs" onClick={() => handleNavLinkClick("/AboutUs")}>About Us</NavLink>
                  </li>
                  <li>
                    <NavLink tag={Link} to="/ContactUs" onClick={() => handleNavLinkClick("/ContactUs")}>Contact Us</NavLink>
                  </li>
                  <li>
                    <NavLink tag={Link} to="/" onClick={() => handleNavLinkClick("/")}>Reservation</NavLink>
                  </li>
                  <li>
                    <NavLink tag={Link} to="/" onClick={() => handleNavLinkClick("/")}>Privacy Policy</NavLink>
                  </li>
                  <li>
                    <NavLink tag={Link} to="/" onClick={() => handleNavLinkClick("/")}>Term & Condition</NavLink>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-3">
              <div className="footer-contact">
                <h4 className="about-title">Contact</h4>
                <p>
                  <FontAwesomeIcon icon={faLocationDot} /> Room No.215,KC Tower,
                  Nawanshahr,Punjab 144514
                </p>
                <p>
                  <FontAwesomeIcon icon={faPhone} /> +91 98760 36661
                </p>
                <p>
                  <FontAwesomeIcon icon={faEnvelope} /> info@hungertime.in
                </p>
              </div>
            </div>

            <div className="col-md-3">
              <div className="footer-timming">
                <h4 className="about-title">Opening</h4>
                <h5>Monday - Saturday</h5>
                <p>09AM - 09PM</p>
                <h5>Sunday</h5>
                <p>10AM - 08PM</p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="copyright-area">
            <div className="row">
              <div className="col-md-6">
                <div className="inner-copyright">
                  <NavLink tag={Link} to="https://eirmonsolutions.com.au/" target="_blank" onClick={() => handleNavLinkClick("https://eirmonsolutions.com.au/")}>© 2023-2024 Eirmon Solutions.</NavLink>
                </div>
              </div>
              <div className="col-md-6">
                <div className="footer-menu">
                  <ul>
                    <li>
                      <NavLink tag={Link} to="/" onClick={() => handleNavLinkClick("/")}>Home</NavLink>
                    </li>

                    <li>
                      <NavLink>Help</NavLink>
                    </li>
                    <li>
                      <NavLink tag={Link} to="/faq" onClick={() => handleNavLinkClick("/faq")}>FAQs</NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
