import React from 'react'
import BlogCard from '../components/BlogCard';
import { Helmet } from "react-helmet";
const Blog = () => {
  return (
    <>
     <Helmet>
        <title>Hungertime restaurant billing software Punjab </title>

        <meta name="keywords" content="best restaurant management tools, top restaurant management software, pos table management" />
        
      </Helmet>
      <BlogCard />
    </>
  )
}

export default Blog;