import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import dividerImg from "../images/divider.png";
import Form from "../components/Form";
const ContactArea = () => {
  return (
    <>
      <section className="banner-area">
        <div className="container">
          <div className="breadcrumb-area">
            <h1>Contact Hungertime For Any Query</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <NavLink>Home</NavLink>
                </li>
                <li className="breadcrumb-item">
                  <NavLink>Pages</NavLink>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Contact Us
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>

      <section className="contact-address-area">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="header-part-brand mb-5">
                <h5 className="Contact-title">Contact Us</h5>
                <div className="divider-area">
                  <img src={dividerImg} alt="" />
                </div>
                <p>
                Hungertime team resides in Punjab. We provide additional services for free even after selling software to our clients. Hungertime software offers the most convenience in today's time. Contact us today to make the journey of taking your restaurant to new heights easier.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="contact-inner">
                <h4 className="about-titles">Address</h4>
                <p>
                  <FontAwesomeIcon icon={faLocationDot} /> Room No.215,KC Tower,
                  Nawanshahr,Punjab 144514
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="contact-inner">
                <h4 className="about-titles">Email</h4>
                <p>
                  <FontAwesomeIcon icon={faEnvelope} /> info@hungertime.in
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="contact-inner">
                <h4 className="about-titles">Phone No.</h4>
                <p>
                  <FontAwesomeIcon icon={faPhone} /> +91 98760 36661
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="form-map ">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="map-area">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d54647.63843362386!2d76.122951!3d31.124254!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391abd0b5a130753%3A0xdbce449f258cb393!2sEirmon%20Solutions!5e0!3m2!1sen!2sin!4v1700049279572!5m2!1sen!2sin"
                  style={{ width: "100%", height: 380, border: 0 }}
                  loading="lazy"
                  title="Embedded Google Map"
                ></iframe>
              </div>
            </div>
            <div className="col-md-6">
              <div className="contact-form">
                <h1>Get A Quote</h1>
                <Form />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactArea;
